import { RootState } from '@/store';
import { sumValuesByKey } from '@/utils/common';
import CustomLink from '@/components/CustomLink';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './style.module.scss';

interface Props {
  // Define the props for your component here
}

const CartLink: React.FC<Props> = (
  {
    /* Destructure props here */
  }
) => {
  const { items } = useSelector((state: RootState) => state.cart || {});
  const [isClient, setIsClient] = useState(false);
  const totalQty = sumValuesByKey(items, 'quantity');

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <CustomLink href="/cart" className={styles.orderLInk}>
      <span>
        <svg width="22" height="20.165" viewBox="0 0 22 20.165">
          <g transform="translate(0 -5.384)">
            <path
              d="M2114.905,71.934a.482.482,0,0,0-.367-.183h-17.408l-.458-2.383c0-.183-.274-.367-.458-.367h-2.754a.459.459,0,1,0,0,.917h2.383l2.2,11.183a3.23,3.23,0,0,0,3.121,2.567H2111.7a.459.459,0,0,0,0-.917h-10.45a2.112,2.112,0,0,1-1.833-1.01l13.383-1.833a.394.394,0,0,0,.366-.365L2115,72.208a.452.452,0,0,0-.092-.275Zm-2.567,7.149-13.388,1.742-1.646-8.25H2113.9Zm-11.55,5.5a2.27,2.27,0,1,0,1.627.665A2.291,2.291,0,0,0,2100.788,84.583Zm0,3.666a1.375,1.375,0,1,1,1.375-1.375A1.41,1.41,0,0,1,2100.788,88.249Zm9.171-3.666a2.291,2.291,0,1,0,2.291,2.291,2.269,2.269,0,0,0-2.291-2.291Zm0,3.666a1.375,1.375,0,1,1,1.375-1.375A1.409,1.409,0,0,1,2109.959,88.249Z"
              transform="translate(-2093 -63.616)"
              fill="#4a4a4a"
            ></path>
          </g>
        </svg>
      </span>
      <p>Cart ({isClient ? totalQty : 0})</p>
    </CustomLink>
  );
};

export default CartLink;
